import * as React from "react";
import styled from "@emotion/styled";
import queryString from "query-string";
import { FeatureImageSection } from "@fitplan/lib/components/FeatureImageSection";
import { SectionTitle } from "@fitplan/lib/components/Header/SectionTitle";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import localize from "../hoc/Localize";
import { useConfig } from "@fitplan/context/lib-es/config";

import "./subscribe.scss";
import "@fitplan/lib/components/LandingPage/plan.scss";
import { TestimonialSection } from "../components/subscribe/TestimonialSection";
import { Subscribe } from "../components/subscribe";
import { IStripePlan } from "@fitplan/lib/types";
import { NewSubscribeButtonBare } from "../components/subscribe/NewSubscribeButton";
import useMount from "react-use/lib/useMount";

export interface Props {
    location: { search: string };
}

export interface IPlanOverride {
    price?: number;
    hide?: boolean;
}

export interface IPromoData {
    coupon?: string;
    showModal?: boolean;
    backgroundUrl?: string;
    mobileBackgroundUrl?: string;
    override: {
        yearly?: IPlanOverride;
        monthly?: IPlanOverride;
    };
}

const getQuery = (search: string): any => {
    const initQuery = queryString.parse(search);
    return Object.entries(initQuery).reduce(
        (acc, [key, value]: [string, string]) => {
            return Object.assign(acc, {
                [key]: value,
            });
        },
        {}
    );
};

const SubscribePage: React.SFC<Props> = props => {
    const { coupon: couponCode, promo } = getQuery(props.location.search);
    const [promoData, setPromoData] = React.useState<IPromoData | null>(null);
    useMount(() => {
        try {
            setPromoData(JSON.parse(atob(decodeURI(promo))));
        } catch (err) {
            console.log("Promo error", err);
        }
    });
    const { imageBaseUrl } = useConfig();
    let filter = null;
    if (promoData) {
        filter = (plan: IStripePlan) => {
            if (
                plan.interval === "month" &&
                promoData.override?.monthly?.hide
            ) {
                return false;
            }
            if (plan.interval === "year" && promoData.override?.yearly?.hide) {
                return false;
            }
            return true;
        };
    }
    let children = null;
    if (promoData?.coupon) {
        children = (
            <NewSubscribeButtonBare
                title="EXISTING MEMBERS"
                price="$4.16"
                interval="mo"
                footer="Renew for just $50"
                text="RENEW AND SAVE"
                onClick={() => (window.location.href = "/giftcard/summer")}
            />
        );
    }

    return (
        <PageWrapper {...props} className="subscribe cms-plan-view">
            <Subscribe
                promoData={promoData}
                couponCode={
                    couponCode?.toUpperCase() ||
                    promoData?.coupon?.toUpperCase()
                }
                filter={filter}
                mobileImageSrc={promoData?.mobileBackgroundUrl}
                title={promoData?.coupon ? "Plan for Summer" : undefined}
                subtitle={
                    promoData?.coupon
                        ? "Get or renew a Fitplan subscription for just $50 and start making plans for Summer '21 today!"
                        : undefined
                }
            >
                {children}
            </Subscribe>
            <TestimonalBox>
                <SectionTitle>Testimonials + Transformations</SectionTitle>
                <TestimonialSection />
            </TestimonalBox>
            <div style={{ marginTop: "32px", marginBottom: "16px" }}>
                <SectionTitle>Fitplan: Your Personal Training App</SectionTitle>
            </div>
            <FeatureImageSection />
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

const TestimonalBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 60px;
    padding-bottom: 48px;

    background-color: #f0f0f0;
`;

export default localize(SubscribePage);
